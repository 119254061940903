<template>
  <!-- Table Container Card -->

  <b-overlay v-if="show == false" :show="show" rounded="sm" no-fade>
    <div   :aria-hidden="show ? 'true' : null">
      <b-card no-body>
        <div class="m-2">
          <b-row style="margin-top: 10px; margin-left: 0px">

            <!-- {{ userData }} -->
            <!-- <b-card
              v-if="checkbox_submit.length > 0"
              title="จำนวนออเดอร์"
              class="mb-2"
              style="
                position: fixed;
                bottom: 50%;
                right: 30px;
                z-index: 99;
                box-shadow: -7px 7px 6px -7px;
              "
            >
              <div>
                สินค้า(เล็ก) : <b>{{ small }}</b> <br />
                สินค้า(กลาง) :<b>{{ middle }}</b> <br />
                สินค้า(ใหญ่) : <b>{{ large }}</b> <br />
              </div>
              <br />

              <b-button
                variant="success"
                class="col-12"
                v-if="checkbox_submit.length > 0 && check_button1 == true"
                @click="Update"
              >
                บันทึก
              </b-button>
            </b-card> -->

            <!-- <b-card
         v-else
        title="จำนวนออเดอร์"
        class="mb-2"
        style=" position: fixed;
     bottom: 5%;
   right: 30px;
    z-index: 99;
    display: none;"
      >
      <div >
          สินค้า(เล็ก) : <b>{{ total.small }}</b> <br /> 
          สินค้า(กลาง) :<b>{{ total.middle }}</b> <br />
          สินค้า(ใหญ่) : <b>{{ total.large }}</b> <br />

        </div>
        <br />
        <b-button  variant="success" class="col-12"  v-if="checkbox_submit.length > 0 && check_button1 == true"   @click="Update">
                 บันทึก </b-button
                >
      </b-card> -->

            <b-col cols="12" md="12">
              <div
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >

              <v-select
    v-model="searchRoute"
    multiple
    label="title"
    :options="options_route"
    style="padding-right: 0rem; padding-left: 0rem"
    class="col-12"
    placeholder="สาย"
    :selectable="() => searchRoute.length < 10"
  />

              <!-- <v-select
                  v-model="searchRoute"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="options_route"
                  class="col-12 mr-2 col-md-12"
                  placeholder="สาย"
                  style="padding-right: 0rem; padding-left: 0rem"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select> -->

                <!-- <v-select
                  v-model="statusFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="options"
                  class="col-4 mr-2"
                  placeholder="ชื่อร้านค้า"
                  style="padding-right: 0rem; padding-left: 0rem"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select> -->
                <!-- <b-form-datepicker
                  id="datepicker-dateformat1"
                  class="col-4 mr-2 col-md-4"
                  v-model="searchStartDate"
                  type="text"
                  placeholder="วันที่เริ่มต้น"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                  locale="en"
                ></b-form-datepicker>

                <b-form-datepicker
                  id="datepicker-dateformat2"
                  class="col-4 mr-2 col-md-4"
                  v-model="searchEndDate"
                  type="text"
                  placeholder="วันที่สิ้นสุด"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                  locale="en"
                ></b-form-datepicker> -->
         
                <!-- {{ check_button }} -->

                <!-- <b-form-input
                            v-model="searchRout"
                            class="d-inline-block mr-2 col-3"
                            placeholder="สาย..."
                        /> -->

            
<!-- 
                <b-button
                  class="ml-2 col-2 col-md-2"
                  variant="secondary"
                  @click="ClearData"
                >
                  เคลียร์ข้อมูล
                </b-button>
                <b-button class="ml-2 col-md-2" variant="success">
                  <download-excel :data="excel"
                  :fields="fields"
                    name="จัดการข้อมูลสาย">
                    ดาวน์โหลด
                  </download-excel></b-button
                >
                <b-button
                  class="ml-2 col-2 col-md-2"
                  variant="primary"
                  @click="createNew()"
                >
                  สร้างรายการ
                </b-button> -->
              </div>
            </b-col>
          </b-row>

          <b-row style="margin-top: 10px; margin-left: 0px">
            <b-col cols="12" md="12" class="mt-2">
              <div
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
           

              <b-button
                  class=" col-2 col-md-2"
                  variant="secondary"
                  @click="ClearData"
                >
                  เคลียร์ข้อมูล
                </b-button>
                <b-button class="ml-2 col-md-2" variant="success">
                  <download-excel :data="excel"
                  :fields="fields"
                    name="จัดการข้อมูลสาย">
                    ดาวน์โหลด
                  </download-excel></b-button
                >
                <b-button
                  class="ml-2 col-2 col-md-2"
                  variant="primary"
                  @click="createNew()"
                >
                  สร้างรายการ 
                </b-button>

                <!-- <b-button
                  class="ml-2 col-2"
                  variant="primary"
                  @click="createNew()"
                >
                  สร้างรายการ
                </b-button> -->
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- tab 2 -->
        <!-- {{ fetchInvoices }}  -->
        <!-- {{ tableColumns }} -->
        <!-- {{ update_data }} -->
        <!-- {{ totalInvoices }}
        {{ totalInvoices1 }}
        {{ fetchInvoices }}  -->
        <!-- {{ fetchInvoices() }} -->
        <!-- <h1>Skor :  {{fetchInvoices[0].item}}</h1> -->

        <b-table
          ref="refInvoiceListTable"
          :items="fetchInvoices"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
          <template #head(invoiceStatus)>
            <feather-icon icon="TrendingUpIcon" class="mx-auto" />
          </template>
          <template #head(transfer_zone)>
            <div style="text-align: left">สาย</div>
            <!-- <feather-icon icon="TrendingUpIcon" class="mx-auto" /> -->
          </template>
          <template #head(total)>
            <div style="text-align: right">รวม</div>
            <!-- <feather-icon icon="TrendingUpIcon" class="mx-auto" /> -->
          </template>

          <template #cell(index)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'index' ? '100%' : '100%',
              }"
            >
              <span>
                <b> {{ data.value }} </b></span
              >
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'index' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>
          <template #cell(order_date)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'order_date' ? '100%' : '100%',
              }"
            >
              <span style="color: green"> {{ data.value }} </span>
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'order_date' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(isActive)="data">
            <div
              :style="{
                width: data.value.key === 'isActive' ? '100%' : '100%',
              }"
            >
              <span class="text-nowrap">
                <div v-if="data.value == 'Y'" style="color: green">
                  <b> ใช้งาน </b>
                </div>
                <div v-else-if="data.value == 'N'" style="color: red">
                  <b> ไม่ใช้งาน </b>
                </div>
              </span>
            </div>
          </template>

          <template #cell(total)="data">
            <div
              v-if="data.value !== ``"
              style="text-align: right"
              :style="{
                width: data.value.key === 'total' ? '100%' : '100%',
              }"
            >
              <span style="color: blue">
                <b> {{ data.value }} </b></span
              >
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'total' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(transferZone)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'transferZone' ? '100%' : '100%',
              }"
            >
              <span> {{ data.value }} </span>
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'transferZone' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

      
       


          <template #cell(updateAt)="data">
            <div
              v-if="data.value !== ``"
              style="text-align: center"
              :style="{
                width: data.value.key === 'updateAt' ? '100%' : '100%',
              }"
            >
              <span> {{ data.value }} </span>
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'updateAt' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <!-- <template #cell(shop_name)="data">
            <div
              v-if="data.value !== ``"
              style="text-align: left"
              :style="{
                width: data.value.key === 'shop_name' ? '100px' : '100px',
              }"
            >
              <span>
                {{ data.value }}
              </span>
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'order_date' ? '100px' : '100px',
              }"
            >
              -
            </div>
          </template> -->

            <template #cell(shopName)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'shopName' ? '100%' : '100%',
              }"
            >
              {{ data.value }}
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'shopName' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>
       

          <template #cell(contactName)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'contactName' ? '100%' : '100%',
              }"
            >
              {{ data.value }}
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'contactName' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(code)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'code' ? '100%' : '100%',
              }"
            >
              {{ data.value }}
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'code' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <!-- <template #cell(address)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'address' ? '100%' : '100%',
              }"
            >
              {{ data.value }}
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'address' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template> -->

          <!-- <template #cell(contactName)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'contactName' ? '100%' : '100%',
              }"
            >
              {{ data.value }}
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'contactName' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template> -->

          <!-- Column: Issued Date -->
          <template #cell(status_order)="data">
            <div
              :style="{
                width: data.value.key === 'status_order' ? '100%' : '100%',
              }"
            >
              <span class="text-nowrap">
                <div v-if="data.value == 'ปกติ'" style="color: green">
                  <b> ปกติ </b>
                </div>
                <div v-else-if="data.value == 'ด่วนที่สุด'" style="color: red">
                  <b> ด่วนที่สุด </b>
                </div>
                <div v-else style="color: #b83f00"><b>เร่งด่วน</b></div>
              </span>
            </div>
          </template>

          <template #cell(actions)="row">
            <div v-if="row.item.checkpage == ``">-</div>
            <div style="text-align: center" v-else>
              <b-form-checkbox
                :style="{
                  width: row.value.key === 'actions' ? '100%' : '100%',
                }"
                v-model="row.item.checkAll"
                name="check-button"
                @input="(value) => UpdateAll(row.item.checkbox, row.item)"
              >
              </b-form-checkbox>
            </div>
          </template>

          <template #cell(update)="row">
            <div v-if="row.item.checkpage == ``">-</div>
            <div style="text-align: center" v-else>
              <b-form-checkbox
                :style="{
                  width: row.value.key === 'actions' ? '100%' : '100%',
                }"
                v-model="row.item.checkbox"
                name="check-button"
                switch
                @click="createNew(row.item.checkbox, row.item)"
              >
              </b-form-checkbox>
            </div>
          </template>

          <!-- <template #cell(update)="row">
            <div v-if="row.item.checkpage == ``">-</div>
            <div  style="text-align: center" v-else>

              <b-button     
           
                  variant="secondary"
                  @click="UpdateQ(true, row.item)"
                >
                  อัพเดท
                </b-button>
        </div>
          </template> -->

          <template #row-details="row">
            <b-card>
              <div class="row">

             

                <div class="col-3">
                  ชื่อร้านค้า 
                  <b-form-input
                    v-model="row.item.shopName"
                    placeholder="Enter your Shopname"
                    type="text"
                  ></b-form-input>
                </div>
                <div class="col-3">
                  ชื่อผู้ติดต่อ
                  <b-form-input
                    v-model="row.item.contactName"
                    placeholder="Enter your Contactname"
                    type="text"
                  ></b-form-input>
                </div>
                <div class="col-3">
                  ที่อยู่
                  <b-form-input
                    v-model="row.item.address"
                    placeholder="Enter your Address"
                    type="text"
                  ></b-form-input>
                </div>
                <div class="col-3">
                  สาย
                  <b-form-input
                    v-model="row.item.transferZone"
                    placeholder="Enter your transferzone"
                    type="text"
                  ></b-form-input>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-3">
                  คลัง
                  <b-form-input
                    v-model="row.item.inventory"
                    placeholder="Enter your Inventory"
                    type="text"
                  ></b-form-input>
                </div>
                <div class="col-3">
                  สถานะ
                  <b-form-input
                    v-model="row.item.isActive"
                    placeholder="Enter your Status"
                    type="text"
                  ></b-form-input>
                </div>
                <div class="col-3">
                  ระดับ
                  <b-form-input
                    v-model="row.item.level"
                    placeholder="Enter your level"
                    type="text"
                  ></b-form-input>
                </div>

                <div class="col-3">
                  <!-- <div class="col-sm"> -->
                  <b-button
                    variant="success"
                    @click="submitDetail(row.item)"
                    style="margin-top: 20px"
                  >
                    บันทึก</b-button
                  >
                  <!-- </div> -->
                </div>
              </div>
            </b-card>
          </template>
        </b-table>

        <template>
          <div>
            <b-modal ref="my-modal1" hide-footer title="อัพเดท">
              <div class="d-block">
                <h6>คุณต้องการอัพเดทข้อมูลหรือไม่</h6>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" variant="success"      @click="submitCreateNew">
                  บันทึก
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  variant="danger"
                  @click="hide(formData_modal)"
                >
                  ปิด
                </button>
              </div>
            </b-modal>
          </div>
        </template>

        <template>
          <div>

          
            <b-modal ref="submit" hide-footer :title="create">
              <div class="d-block">
                <!-- <select class="form-control" v-model="formData_modal.shopName">
                  <option
                    v-for="option in options_shopname"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select> -->
            
                <!-- test : {{ formData_modal.transferZone  }}
                test : {{ formData_modal.formData_modal.isActive  }} -->
                <!-- test : {{ formData_modal[0].transferZone  }} -->

                <!-- {{ formData_modal  }} -->

                 <!-- <select class="form-control mt-1" v-model="formData_modal.transferZone">
                  <option disabled
                  >
                    {{formData_modal.transferZone }}
                  </option>
                  <option
                    v-for="option in options_route_a"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select> -->

               

                 <!-- <select class="form-control mt-1" v-model="formData_modal.transferZone"  v-else>
                  <option
                    v-for="option in options_route_a"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select> -->


           

                <b-row class="my-1">
                  <b-col sm="2">
                    <label for="input-small">สาย:</label>
                  </b-col>
                  <b-col sm="10">
                    <b-form-input
                      id="input-small"
                      type="text"
                      size="sm"
                      placeholder="Enter your route"
                      v-model="formData_modal.route"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <select class="form-control mt-1" v-model="formData_modal.isActive">
                  <option
                    v-for="option in options_status_a"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>

                <!-- <b-row class="my-1">
                  <b-col sm="2">
                    <label for="input-small">สถานะ:</label>
                  </b-col>
                  <b-col sm="10">
                    <b-form-input
                      id="input-small"
                      type="text"
                      size="sm"
                      placeholder="Enter your inventory"
                      v-model="formData_modal.inventory"
                    ></b-form-input>
                  </b-col>
                </b-row> -->

               


                <!-- <h6> custCode: "", </h6><b-form-input
                            v-model="searchRout"
                            class=""
                            placeholder="สาย..."
                        /> -->
                <!-- <h6>  code: "",</h6> -->
                <!-- <h6> sizeSmall: 0,</h6>
               <h6>  sizeMiddle: 0,</h6>
               <h6>  sizeLarge: 0,</h6> -->
                <!-- <h6> remark: '',</h6>
               <h6> total: 0,</h6>
               <h6>  lineId : userProfile.userId,</h6>
               <h6>  chatId:'',</h6>
               <h6>  role:'R'</h6> -->
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  variant="success"
                  @click="check()"
                  
                  v-if="formData_modal.route != ``
                    "
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  variant="success"
                  v-else
                  disabled
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  variant="danger"
                  @click="hideModal(formData_modal)"
                >
                  ปิด
                </button>
              </div>
            </b-modal>
          </div>
        </template>

        <!-- <template #cell(actions1)="data">
            <div class="text-nowrap">
                <feather-icon
                    :id="`invoice-row-${data.item.id}-send-icon`"
                    icon="SendIcon"
                    class="cursor-pointer"
                    size="16"
                />
            </div> </template> -->
        <!-- </b-card> -->
        <!-- </template> -->
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="searchEndDate1"
                :total-rows="totalInvoices"
                :per-page="currentPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BFormDatepicker,
  BCollapse,
  BFormCheckbox,
  BFormCheckboxGroup,
  BOverlay,
  BCardText,
} from "bootstrap-vue";
import { useWindowScroll } from "@vueuse/core";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useInvoicesList from "./useInvoiceList";
import axios from "axios";
import invoiceStoreModule from "../invoiceStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BFormDatepicker,
    vSelect,
    flatPickr,
    BCollapse,
    BFormCheckbox,
    BFormCheckboxGroup,
    BOverlay,
    ToastificationContent,
    BCardText,
  },
  data() {
    return {
      fields: {
              "No": "index",
              "สาย": "name",
              "สถานะ": "isActive",
              "วันที่สร้าง": "createAt",
              "วันที่สร้าง": "updateAt"
            },
            updated:"",
      create:`สร้างรายการ`,
      checkbox_submit: ``,
      show: false,
      message: "u",
      options: [],
      options_route: [],
      options_carLicense: [],
      options_shopname: [{ text: "ระบุชื่อร้าน", value: "" }],
      options_route_a: [{ text: "ระบุชื่อสาย", value: "" }],
      options_status_a: [
        { text: "ระบุชื่อสถานะ", value: "" },
        { text: "ใช้งาน", value: "Y" },
        { text: "ไม่ใช้งาน", value: "N" }
  
    ],
      options_level: [{ text: "ระบุชื่อระดับ", value: "" },
      { text: "ด่วนที่สุด", value: "A" },
        { text: "เร่งด่วน", value: "B" },
        { text: "ปกติ", value: "C" }
      ],
      small: 0,
      middle: 0,
      large: 0,
      checked: false,
        formData_modal: {
            id: "",
            route: "",
            isActive:"Y"   
      },
      total: {
        small: 0,
        middle: 0,
        large: 0,
      },
      items: [
        {
          isActive: true,
          age: 40,
          name: { first: "Dickerson", last: "Macdonald" },
        },
        {
          isActive: false,
          age: 21,
          name: { first: "Larsen", last: "Shaw" },
        },
        {
          isActive: false,
          age: 9,
          name: { first: "Mini", last: "Navarro" },
          _rowVariant: "success",
        },
        {
          isActive: false,
          age: 89,
          name: { first: "Geneva", last: "Wilson" },
        },
        {
          isActive: true,
          age: 38,
          name: { first: "Jami", last: "Carney" },
        },
        {
          isActive: false,
          age: 27,
          name: { first: "Essie", last: "Dunlap" },
        },
        {
          isActive: true,
          age: 40,
          name: { first: "Thor", last: "Macdonald" },
        },
        {
          isActive: true,
          age: 87,
          name: { first: "Larsen", last: "Shaw" },
          _cellVariants: { age: "danger", isActive: "warning" },
        },
        {
          isActive: false,
          age: 26,
          name: { first: "Mitzi", last: "Navarro" },
        },
        {
          isActive: false,
          age: 22,
          name: { first: "Genevieve", last: "Wilson" },
        },
        {
          isActive: true,
          age: 38,
          name: { first: "John", last: "Carney" },
        },
        {
          isActive: false,
          age: 29,
          name: { first: "Dick", last: "Dunlap" },
        },
      ],
      // fields: [
      //   {
      //     key: "name",
      //     label: "Person full name",
      //     sortable: true,
      //     sortDirection: "desc",
      //   },
      //   {
      //     key: "age",
      //     label: "Person age",
      //     sortable: true,
      //     class: "text-center",
      //   },
      //   {
      //     key: "isActive",
      //     label: "Is Active",
      //     formatter: (value, key, item) => {
      //       return value ? "Yes" : "No";
      //     },
      //     sortable: true,
      //     sortByFormatted: true,
      //     filterByFormatted: true,
      //   },
      //   { key: "actions", label: "Actions" },
      // ],
      formData: {
        searchStartDate: "",
        carLicense: "",
        searchRob: "",
      },

      totalRows: 1,
      selected: "A",
      options_test: [
        { text: "One", value: "A" },
        { text: "Two", value: "B" },
        { text: "Three", value: "C" },
      ],
      //   currentPage: 1,
      //   perPage: 5,
      //   pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      update_data: [],
      check_button: [],
      check_button1: false,
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      date_update: [],
    };
  },
  //     watch: {
  //         totalInvoices1: {
  //     handler(newName, oldName) {
  //       console.log('obj.a changed');
  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // },
  watch: {
    totalInvoices1: {
      // console.log("")
      handler(val) {
        // console.log(val);
        // console.log("val" , val)
        // console.log("val" , val)
        this.UpdateQ(val);

        // for (let i = 0; i < val.length; i++) {
        //     if (val[i].checkbox == true) {
        //         console.log("test1");
        //         // return
        //     } else {
        //         console.log("test2");
        //     }
        // }

        // for (let i = 0; i < val.length; i++) {

        //     if(val[i].checkbox === true){

        //         // if(val[i].code == this.update_data){

        //         // }

        //         this.update_data.push( val[i])
        //         console.log("val" , val[i])
        //         return 0;

        //     }

        // this.options.push(response.data[i].shopName);
        // }
      },
      deep: true,
    },
    formData: {
      handler(val) {
        // console.log(val);
        if (
          this.formData.searchStartDate != "" &&
          this.formData.carLicense != "" &&
          this.formData.searchRob != "" &&
          this.formData.searchStartDate != null &&
          this.formData.carLicense != null &&
          this.formData.searchRob != null
        ) {
          //   console.log("1");
          this.check_button1 = true;
        } else {
          //   console.log("2");
          this.check_button1 = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    check() {
      // this.$refs["submit"].hide();
      // this.$refs["submit"].hide();
      this.$refs["submit"].hide();
      this.$refs["my-modal1"].show();
      //submitDetail(row.item)
      // this.updated = x
      // console.log(this.updated)
     
      
      // this.update_invoice();
      // UpdateQ(row.item.checkbox, row.item)
    },
    hide(e){
      (
        this.formData_modal = {
          id: "",
            code : "",
            route: "",
            isActive:"Y"   
      }
      ),

      console.log("test" , e);
if(e){
  for (let i = 0; i < this.update_data.length; i++) {
         if( e.checkbox == true){
            this.update_data[i].checkbox = false
            console.log("Check" ,  this.update_data[i])
         }else{
          this.update_data[i].checkbox = false
         }

        }
}
      this.$refs["my-modal1"].hide();

    },
    hideModal(e) {


     

      (
        this.formData_modal = {
          id: "",
            code : "",
            route: "",
            isActive:"Y"   
      }
      ),

      console.log("test" , e);
if(e){
  for (let i = 0; i < this.update_data.length; i++) {
         if( e.checkbox == true){
            this.update_data[i].checkbox = false
            console.log("Check" ,  this.update_data[i])
         }else{
          this.update_data[i].checkbox = false
         }

        }
}

        this.$refs["submit"].hide();
    },

    // async CheckRegister() {
    //   try {
    //     const response = await axios.post(
    //       `http://202.129.206.107:11009/listshop`
    //     );


    //     for (let i = 0; i < response.data.length; i++) {
    //       this.options.push(response.data[i].shopName);
    //     }

    //     for (let j = 0; j < response.data.length; j++) {
    //       let obj = {
    //         text: response.data[j].shopName,
    //         value: response.data[j].code,
    //       };

    //       this.options_shopname.push(obj);
    //     }

    //   } catch (errors) {
    //     console.error(errors);
    //   }
    // },

    async submitDetail(data) {
      this.show = true;
      let payload = {
        id: data.id,
        shopName: data.shopName,
        contactName: data.contactName,
        address: data.address,
        lineId: data.lineId,
        inventory: data.inventory,
        chatId: data.chatId,
        transferZone: data.transferZone,
        level: data.level,
      };

      const userData = JSON.parse(localStorage.getItem("userData"));
      console.log("userData" , userData)

      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/routeSave`,
          payload
        );

        if (response.data.status == "200") {
          (this.formData_modal = {
            id: "",
            code : "",
            name: "",
            isActive:"Y"   
  
          }),
          this.ClearData();
          this.ClearDataUpdate();
          // this.CheckRegister();
          // this.getCar();
          // this.getBranch();
          this.getRoute();
          // this.$refs["submit"].hide();

          // this.show = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `บันทึกรายการสำเร็จ`,
              icon: "UserIcon",
              variant: "success",
            },
          });

          this.show = false;

          // this.show = false;
        }

        // for (let i = 0; i < response.data.length; i++) {
        //   this.options.push(response.data[i].shopName);
        // }

        // return todoItems;
      } catch (errors) {
        console.error(errors);
      }
    },

    async createNew(e) {
      this.create = `สร้างรายการ`

      for (let i = 0; i < this.update_data.length; i++) {
         if( this.update_data.checkbox == true){
            this.update_data[i].checkbox = false
         }else{
          this.update_data[i].checkbox = false
         }

        }
     
      this.$refs["submit"].show();
      console.log("create")
    },
    async submitCreateNew(e) {
      this.show = true;
      console.log("test", e);


//       parameter : route , isActive , id
// id = '' จะ insert ถ้า !='' จะ updat

const userData = JSON.parse(localStorage.getItem("userData"));
      console.log("userData" , userData.id)


let payload
      if(this.formData_modal.id == ``){
        payload = {
            id: "",
            name: this.formData_modal.route,
            isActive: this.formData_modal.isActive,
            loginId: userData.id
          }
      }else{

        payload = {
            id: this.formData_modal.id,
            name: this.formData_modal.route,
            isActive: this.formData_modal.isActive,
            loginId: userData.id
          }

        // payload = this.formData_modal
        // payload = {
        //     id: "",
        //     code : "",
        //     shopName: this.formData_modal.shopName ? this.formData_modal.shopName :"",
        //     contactName: this.formData_modal.contactName ? this.formData_modal.contactName :"",
        //     address: this.formData_modal.address ? this.formData_modal.address :"",
        //     lineId: this.formData_modal.lineId ? this.formData_modal.lineId :"",
        //     inventory: this.formData_modal.inventory ? this.formData_modal.inventory :"",
        //     chatId: this.formData_modal.chatId ? this.formData_modal.chatId :"",
        //     transferZone: this.formData_modal.transferZone ? this.formData_modal.transferZone :"",
        //     level: this.formData_modal.level ? this.formData_modal.level :"",
        //   }
      }

      // this.$refs["submit"].show();
      // this.$refs["submit"].hide();
      // this.show = true;
      // this.$refs["submit"].show();
      // let payload = {
      // custCode: e.shop_code,
      // sizeSmall: e.max_small,
      // sizeMiddle: e.max_middle,
      // sizeLarge: e.max_large,
      // remark: e.remark
      // };

   

      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/routeSave`,
          payload
        );

        if (response.data.status == "200") {
          console.log("test01 save succsess")

          this.formData_modal = {
            id: "",
            route: "",
            isActive:"Y"     
            
    
          },

          console.log("test01 save succsess1")

          this.ClearData();
          this.ClearDataUpdate();
          // this.CheckRegister();
          // this.getCar();
          // this.getBranch();
          this.getRoute();
          // this.$refs["submit"].hide();

          // this.show = false;
          console.log("test01 save succsess2"
          )
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `บันทึกรายการสำเร็จ`,
              icon: "UserIcon",
              variant: "success",
            },
          });

          this.show = false;
        }else{

       
          (this.formData_modal = {
            id: "",
            route: "",
            isActive:"Y"   
          }),

          this.ClearData();
          this.ClearDataUpdate();
          // this.CheckRegister();
          // this.getCar();
          // this.getBranch();
          this.getRoute();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `บันทึกรายการสำเร็จ`,
              icon: "UserIcon",
              variant: "success",
            },
          });

          this.show = false;
        }
      } catch (errors) {
        console.error(errors);
      }
    },
    async getRoute() {
      console.log("1234")
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/routeList`,{
    search: ""
}
        );

        // console.log("respons111111111e", response);

        //    response.data;

        for (let i = 0; i < response.data.data.length; i++) {
          this.options_route.push(response.data.data[i].name);
          console.log("44444")
        }

        for (let j = 0; j < response.data.data.length; j++) {
          let obj = {
            text: response.data.data[j].name,
            value: response.data.data[j].name,
          };

          // res.data[i].branchName
          //   text += cars[i] + "<br>";
          this.options_route_a.push(obj);
        }

        // return todoItems;
      } catch (errors) {
        console.error(errors);
      }
    },
    async UpdateAll(e, items) {
      console.log(e, items);
      console.log("update_data", this.update_data);

      this.show = true;
      //   console.log("e555", e, items);

      // this.date_update = e;

      // this.update_data = e;

      // console.log("update_data",update_data)

      //   console.log("this.update_datatttttttttttttttttttt", this.update_data);
      //   console.log("this.date_update ", this.date_update);

      let b = [];

      // this.date_update

      //   console.log("this.update_data", this.date_update);

      for (var i = 0; i < this.update_data.length; i++) {
        console.log("checkall", this.update_data[i].checkAll);
        if (this.update_data[i].checkAll == true) {
          this.update_data[i].max_small = this.update_data[i].size_small;
          this.update_data[i].max_middle = this.update_data[i].size_middle;
          this.update_data[i].max_large = this.update_data[i].size_large;
          // console.log("1" ,  this.date_update[i]);

          // this.update_data[i].size_small = `10`
          // this.update_data[i].size_middle = `10`
          // this.update_data[i].size_large = `10`
          // this.update_data[i].max_large = this.date_update[i].max_large

          b.push(this.update_data[i]);
        } else {
          //   console.log("2");
        }
        //   if ( b.indexOf( this.update_data[i].checkbox ) == true ) b.push( this.update_data[i]  );
      }

      console.log("22222222", b);

      this.check_button = b;
      this.checkbox_submit = b;
      console.log("2222", b);

      let num_small = 0;
      let num_middle = 0;
      let num_large = 0;

      console.log("checkbox_submit", this.checkbox_submit);

      for (let i = 0; i < this.checkbox_submit.length; i++) {
        num_small = num_small + parseInt(this.checkbox_submit[i].size_small);
        num_middle = num_middle + parseInt(this.checkbox_submit[i].size_middle);
        num_large = num_large + parseInt(this.checkbox_submit[i].size_large);
        // this.total.small = num_small;
        // console.log("num" , num_small)
      }

      this.small = num_small;
      this.middle = num_middle;
      this.large = num_large;

      console.log("total", this.small);

      // console.log("num1" , num)
      // total.small = total.small + this.check_button[i].max_small
      // if (this.check_button[i].max_small == true) {
      // console.log("1" ,  this.date_update[i]);
      // this.update_data[i].size_small = `10`
      // this.update_data[i].size_middle = `10`
      // this.update_data[i].size_large = `10`
      // this.update_data[i].max_large = this.date_update[i].max_large
      // b.push(this.update_data[i]);
      // } else {
      //     console.log("2");
      // }
      //   if ( b.indexOf( this.update_data[i].checkbox ) == true ) b.push( this.update_data[i]  );
      // }

      console.log("test01", this.total);
      this.show = false;

      //  for (let i = 0; i < response.data.length; i++) {
      //                 this.options.push(response.data[i].shopName);
      // //             }
      //         console.log("test" ,e ,x )

      //         if(e == true ){

      //             this.update_data.push(x)
      //         }else{

      //             //เช็ค x.code ว่ามีในนี้ไหม  update_data

      //             for (let i = 0; i < this.update_data.length; i++) {
      //                 console.log("x.code ", x.code  , this.update_data[i].code)

      //                 if(x.code == this.update_data[i].code){
      //                     console.log("1")
      //                     // this.update_data.splice(this.update_data.indexOf( x.code  ), 1);
      //                 }else{
      //                     console.log("2")
      //                 }

      //                 // this.options.push(response.data[i].shopName);
      //             }

      //             //ถ้ามี ลบ update_data.code
      //         console.log("555",this.update_data)

      //         }

      //         console.log("update_data", this.update_data)

      // try {
      //     const response = await axios.post(
      //         `http://202.129.206.107:11009/listshop`
      //     );

      //     console.log("response", response);

      //     for (let i = 0; i < response.data.length; i++) {
      //         this.options.push(response.data[i].shopName);
      //     }

      // } catch (errors) {
      //     console.error(errors);
      // }
    },
    async UpdateQ(e, items) {
      // console.log("test",e,items)
      this.show = true;
      //   console.log("e555", e, items);

      // this.date_update = e;

      this.update_data = e;

      console.log("e555", e, this.update_data);

      //   console.log("this.update_datatttttttttttttttttttt", this.update_data);
      //   console.log("this.date_update ", this.date_update);

      let b = [];
      let c = {}

      // this.date_update

      //   console.log("this.update_data", this.date_update);

      for (var i = 0; i < this.update_data.length; i++) {
        if (this.update_data[i].checkbox == true) {
          this.create = `อัพเดทรายการ`
          this.$refs["submit"].show();
        b =  this.update_data[i]
        
        
          // console.log("1" ,  this.date_update[i]);

          // this.update_data[i].size_small = `10`
          // this.update_data[i].size_middle = `10`
          // this.update_data[i].size_large = `10`
          // this.update_data[i].max_large = this.date_update[i].max_large

          // b.push(this.update_data[i]);
          break;
        } else {
          this.create = `สร้างรายการ`
         b = {
            id: "",
            route: "",
            isActive:"Y"   
          }
          //   console.log("2");
        }

        //   if ( b.indexOf( this.update_data[i].checkbox ) == true ) b.push( this.update_data[i]  );
      }

      //   console.log("22222222", b);
      this.check_button = b;
      this.formData_modal = b;
      // this.formData_modal = this.check_button[0];


      // this.formData_modal = {
      //       id: this.check_button.id,
      //       shopName: this.check_button.shopName,
      //       contactName: this.check_button.contactName,
      //       address: this.check_button.address,
      //       lineId: this.check_button.lineId,
      //       inventory: this.check_button.inventory,
      //       chatId: this.check_button.chatId,
      //       transferZone: this.check_button.transferZone,
      //       level: this.check_button.level,
      //       isActive:this.check_button.isActive
      // },

      // let num_small = 0;
      // let num_middle = 0;
      // let num_large = 0;

      // for (let i = 0; i < this.check_button.length; i++) {
      //   num_small = num_small + parseInt(this.check_button[i].max_small);
      //   num_middle = num_middle + parseInt(this.check_button[i].max_middle);
      //   num_large = num_large + parseInt(this.check_button[i].max_large);
      // }

      // this.total.small = num_small;
      // this.total.middle = num_middle;
      // this.total.large = num_large;

      // console.log("num1" , num)
      // total.small = total.small + this.check_button[i].max_small
      // if (this.check_button[i].max_small == true) {
      // console.log("1" ,  this.date_update[i]);
      // this.update_data[i].size_small = `10`
      // this.update_data[i].size_middle = `10`
      // this.update_data[i].size_large = `10`
      // this.update_data[i].max_large = this.date_update[i].max_large
      // b.push(this.update_data[i]);
      // } else {
      //     console.log("2");
      // }
      //   if ( b.indexOf( this.update_data[i].checkbox ) == true ) b.push( this.update_data[i]  );
      // }

      console.log("test01");
      this.show = false;

      //  for (let i = 0; i < response.data.length; i++) {
      //                 this.options.push(response.data[i].shopName);
      // //             }
      //         console.log("test" ,e ,x )

      //         if(e == true ){

      //             this.update_data.push(x)
      //         }else{

      //             //เช็ค x.code ว่ามีในนี้ไหม  update_data

      //             for (let i = 0; i < this.update_data.length; i++) {
      //                 console.log("x.code ", x.code  , this.update_data[i].code)

      //                 if(x.code == this.update_data[i].code){
      //                     console.log("1")
      //                     // this.update_data.splice(this.update_data.indexOf( x.code  ), 1);
      //                 }else{
      //                     console.log("2")
      //                 }

      //                 // this.options.push(response.data[i].shopName);
      //             }

      //             //ถ้ามี ลบ update_data.code
      //         console.log("555",this.update_data)

      //         }

      //         console.log("update_data", this.update_data)

      // try {
      //     const response = await axios.post(
      //         `http://202.129.206.107:11009/listshop`
      //     );

      //     console.log("response", response);

      //     for (let i = 0; i < response.data.length; i++) {
      //         this.options.push(response.data[i].shopName);
      //     }

      // } catch (errors) {
      //     console.error(errors);
      // }
    },
    async Update() {
      this.show = true;
      // console.log("e555" , e)

      // this.update_data = e

      //  for (let i = 0; i < response.data.length; i++) {
      //                 this.options.push(response.data[i].shopName);
      //             }
      // console.log("test" , totalInvoices1)

      //     console.log("this.update_data",   this.update_data)

      let b = [];
      // this.$refs["my-modal1"].show();

      // this.date_update

      console.log("this.update_data", this.update_data);

      for (var i = 0; i < this.update_data.length; i++) {
        if (this.update_data[i].checkAll == true) {
          // console.log("1" ,  this.date_update[i]);

          // this.update_data[i].size_small = `10`
          // this.update_data[i].size_middle = `10`
          // this.update_data[i].size_large = `10`
          // this.update_data[i].max_large = this.date_update[i].max_large

          b.push(this.update_data[i]);
        } else {
          //   console.log("2");
        }
        //   if ( b.indexOf( this.update_data[i].checkbox ) == true ) b.push( this.update_data[i]  );
      }

      //      console.log("this.update_data[i] " , this.update_data[i] )

      // let test = {
      //     id: 18,
      //     code: "P00005",
      //     orderDate: "2023-03-23 00:35:35",
      //     dueDate: null,
      //     transferDate: "2023-03-23 00:35:35",
      //     custCode: "6603002",
      //     sizeSmall: "12455",
      //     sizeMiddle: 1881,
      //     sizeLarge: 1112,
      //     remark: "ttyy",
      //     total: 4238,
      //     statusOrder: "ปกติ",
      //     statusMain: "รอจัดรถ",
      //     updateDate: "2023-03-29 14:19:48",
      //     lineId: "Uafc47e26b9bb051412f9ecbf0c06346b",
      //     chatId: "U65410582defe7d3b679c62670ad6de44",
      // };
      //   console.log(" b", b);
      // console.log(" test", b);

      // if(e == true ){

      //     this.update_data.push(x)
      // }

      let payload = {
        transferDate: this.formData.searchStartDate,
        license: this.formData.carLicense,
        round: this.formData.searchRob,
        staffId: 1,
        row: b,
      };

      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/savetransfer`,
          payload
        );

        if (response.data.status == "200") {
          this.ClearData();
          this.ClearDataUpdate();
          // this.CheckRegister();
          // this.getCar();
          // this.getBranch();
          this.getRoute();

          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `บันทึกรายการสำเร็จ`,
              icon: "UserIcon",
              variant: "success",
            },
          });
        }
      } catch (errors) {
        console.error(errors);
      }
    },

    ClearData() {
      //   console.log("1", this.searchStartDate);
      this.searchStartDate = "";
      this.searchEndDate = "";
      // this.searchRout = "";
      this.searchStatus = "";
      this.searchStatusMain = "รอจัดรถ";
      this.searchRoute = "";
      this.statusFilter = "";
      this.checkbox_submit = "";
      this.small = 0;
      this.middle = 0;
      this.large = 0;
    },
    ClearDataUpdate() {
      this.formData = {
        searchStartDate: "",
        carLicense: "",
        searchRob: "",
      };
      // console.log("1", this.searchStartDate);
      // this.searchStartDate = "";
      // this.searchEndDate = "";
      // this.searchRout = "";
      // this.searchStatus = "";
      // this.searchRoute = "";
      // this.statusFilter = "";
    },
    // total() {
    //   console.log("test");
    // },
  },
  mounted() {
    // this.CheckRegister();
    // this.getCar();
    // this.getBranch();
    this.getRoute();

    // console.log("1");
  },
  setup() {
    const { y } = useWindowScroll();

    const scrollToTop = () => {
      const rootEle = document.documentElement;
      rootEle.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    // const statusOptions = [
    //   'Downloaded',
    //   'Draft',
    //   'Paid',
    //   'Partial Payment',
    //   'Past Due',
    // ]

    //const statusOptions = ["ด่วนมาก", "ด่วน", "ปกติ"];
    const statusOptions = ["ปกติ", "เร่งด่วน", "ด่วนที่สุด"];
    const statusMainOptions = ["รอจัดรถ", "จัดรถแล้ว"];
    const Optionsrob = ["1", "2", "3"];

    // const statusOptionsshop = [
    // ]

    const {
      fetchInvoices,
      fetch_shop,
      tableColumns,
      perPage,
      excel,
      currentPage,
      totalInvoices,
      totalInvoices1,
      totalInvoices2,
      totalInvoices3,
      totalInvoices4,
      max_large,
      max_middle,
      max_small,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchStartDate,
      searchEndDate1,
      searchEndDate,
      searchStatus,
      searchStatusMain,
      searchRoute,
      searchRob,

      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      params,
      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList();

    return {
      fetchInvoices,
      fetch_shop,
      tableColumns,
      perPage,
      excel,
      currentPage,
      totalInvoices,
      totalInvoices1,
      totalInvoices2,
      totalInvoices3,
      totalInvoices4,
      max_large,
      max_middle,
      max_small,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      searchEndDate1,
      searchStartDate,
      searchEndDate,
      searchStatus,
      searchStatusMain,
      searchRoute,
      searchRob,

      statusFilter,
      //   statusOptionsshop,
      Optionsrob,
      refetchData,
      params,
      statusOptions,
      statusMainOptions,
      y,
      scrollToTop,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    };
  },
};
</script>

<style lang="scss" scoped>
.hidden_header {
  display: none;
}
.per-page-selector {
  width: 90px;
}

// .btn-scroll-to-top {
//   position: fixed;
//     bottom: 5%;
//     right: 30px;
//     z-index: 99;

//     opacity: 0;
//     // transform: translateY(100px);
//     transition: all .5s ease;

//     &.show {
//       opacity: 1;
//       // transform: translateY(0)
//     }
// }
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

// .invoice-filter-select[data-v-5cb60a6f] {
//     min-width: 213px;
// }
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

// table, td, th {
//   border: 1px solid black;
// }

table {
  //   border-collapse: collapse;
  width: 120%;
}

th {
  height: 70px;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #f3f2f7;
  text-align: center;
}
[dir] .table th,
[dir] .table td {
  // padding: 0.72rem 2rem;
  text-align: center;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem 0.4rem;
  text-align: center;
}
</style>
